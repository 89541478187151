// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-educators-chalk-drawing-js": () => import("./../../../src/pages/educators/chalk-drawing.js" /* webpackChunkName: "component---src-pages-educators-chalk-drawing-js" */),
  "component---src-pages-educators-family-enrichment-js": () => import("./../../../src/pages/educators/family-enrichment.js" /* webpackChunkName: "component---src-pages-educators-family-enrichment-js" */),
  "component---src-pages-educators-index-js": () => import("./../../../src/pages/educators/index.js" /* webpackChunkName: "component---src-pages-educators-index-js" */),
  "component---src-pages-educators-inspiration-js": () => import("./../../../src/pages/educators/inspiration.js" /* webpackChunkName: "component---src-pages-educators-inspiration-js" */),
  "component---src-pages-educators-planning-resources-js": () => import("./../../../src/pages/educators/planning-resources.js" /* webpackChunkName: "component---src-pages-educators-planning-resources-js" */),
  "component---src-pages-educators-recommendations-js": () => import("./../../../src/pages/educators/recommendations.js" /* webpackChunkName: "component---src-pages-educators-recommendations-js" */),
  "component---src-pages-educators-reflections-js": () => import("./../../../src/pages/educators/reflections.js" /* webpackChunkName: "component---src-pages-educators-reflections-js" */),
  "component---src-pages-educators-social-emotional-learning-js": () => import("./../../../src/pages/educators/social-emotional-learning.js" /* webpackChunkName: "component---src-pages-educators-social-emotional-learning-js" */),
  "component---src-pages-educators-student-materials-guide-js": () => import("./../../../src/pages/educators/student-materials-guide.js" /* webpackChunkName: "component---src-pages-educators-student-materials-guide-js" */),
  "component---src-pages-educators-tips-and-tricks-js": () => import("./../../../src/pages/educators/tips-and-tricks.js" /* webpackChunkName: "component---src-pages-educators-tips-and-tricks-js" */),
  "component---src-pages-newsletter-index-js": () => import("./../../../src/pages/newsletter/index.js" /* webpackChunkName: "component---src-pages-newsletter-index-js" */),
  "component---src-pages-students-arts-and-crafts-js": () => import("./../../../src/pages/students/arts-and-crafts.js" /* webpackChunkName: "component---src-pages-students-arts-and-crafts-js" */),
  "component---src-pages-students-fingerplays-js": () => import("./../../../src/pages/students/fingerplays.js" /* webpackChunkName: "component---src-pages-students-fingerplays-js" */),
  "component---src-pages-students-games-js": () => import("./../../../src/pages/students/games.js" /* webpackChunkName: "component---src-pages-students-games-js" */),
  "component---src-pages-students-index-js": () => import("./../../../src/pages/students/index.js" /* webpackChunkName: "component---src-pages-students-index-js" */),
  "component---src-pages-students-math-games-js": () => import("./../../../src/pages/students/math-games.js" /* webpackChunkName: "component---src-pages-students-math-games-js" */),
  "component---src-pages-students-monthly-challenges-js": () => import("./../../../src/pages/students/monthly-challenges.js" /* webpackChunkName: "component---src-pages-students-monthly-challenges-js" */),
  "component---src-pages-students-movement-js": () => import("./../../../src/pages/students/movement.js" /* webpackChunkName: "component---src-pages-students-movement-js" */),
  "component---src-pages-students-music-js": () => import("./../../../src/pages/students/music.js" /* webpackChunkName: "component---src-pages-students-music-js" */),
  "component---src-pages-students-poems-and-verses-js": () => import("./../../../src/pages/students/poems-and-verses.js" /* webpackChunkName: "component---src-pages-students-poems-and-verses-js" */),
  "component---src-pages-students-read-alouds-js": () => import("./../../../src/pages/students/read-alouds.js" /* webpackChunkName: "component---src-pages-students-read-alouds-js" */),
  "component---src-pages-students-recipes-js": () => import("./../../../src/pages/students/recipes.js" /* webpackChunkName: "component---src-pages-students-recipes-js" */),
  "component---src-pages-students-science-js": () => import("./../../../src/pages/students/science.js" /* webpackChunkName: "component---src-pages-students-science-js" */),
  "component---src-pages-students-social-emotional-learning-js": () => import("./../../../src/pages/students/social-emotional-learning.js" /* webpackChunkName: "component---src-pages-students-social-emotional-learning-js" */),
  "component---src-pages-students-spelling-games-js": () => import("./../../../src/pages/students/spelling-games.js" /* webpackChunkName: "component---src-pages-students-spelling-games-js" */),
  "component---src-pages-students-stories-js": () => import("./../../../src/pages/students/stories.js" /* webpackChunkName: "component---src-pages-students-stories-js" */),
  "component---src-pages-support-us-index-js": () => import("./../../../src/pages/support-us/index.js" /* webpackChunkName: "component---src-pages-support-us-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-educator-resource-js": () => import("./../../../src/templates/educator-resource.js" /* webpackChunkName: "component---src-templates-educator-resource-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-student-resource-js": () => import("./../../../src/templates/student-resource.js" /* webpackChunkName: "component---src-templates-student-resource-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

